import { Loader } from "@storybook";
import { ChangeEventHandler, FC, Fragment, useMemo } from "react";

import "./file-upload.scss";

interface IFileUpload {
  handleChange: ChangeEventHandler<HTMLInputElement>;
  isLoading?: boolean;
  isUploaded?: "initial" | "uploading" | "uploaded";
  fileName?: string;
}

export const FileUpload: FC<IFileUpload> = ({
  handleChange,
  isUploaded = "initial",
  fileName,
}) => {
  const innerBody = useMemo(() => {
    if (isUploaded === "initial") {
      return (
        <Fragment>
          <i className="ri-file-upload-line file-upload__inner__upload-icon"></i>
          <div className="file-upload__inner__label">
            Drag and drop files or <span>Browse</span>
          </div>
        </Fragment>
      );
    }
    if (isUploaded === "uploading") {
      return (
        <div>
          <Loader type="circle" className="loader-blue" />;
          <div className="file-upload__inner__label">
            Uploading file, please wait...
          </div>
        </div>
      );
    } else
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <i className="ri-check-line file-upload__inner__upload-icon"></i>
          {/* <div className="file-upload__inner__label">
            Your file has been uploaded successfully
          </div> */}
          <div className="file-upload__inner__label">File: {fileName}</div>
        </div>
      );
  }, [fileName, isUploaded]);

  const handleClick = (event: any) => {
    const { target } = event || {};
    target.value = "";
  };

  return (
    <div className="file-upload">
      <label htmlFor="file-upload">
        <div className="file-upload__inner">{innerBody}</div>
      </label>
      <input
        type="file"
        id="file-upload"
        onChange={handleChange}
        hidden
        onClick={handleClick}
      />
    </div>
  );
};
